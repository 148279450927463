<template>
  <div>
    <haul-form :form-is-open="formIsOpen" @closeForm="closeForm()" @submitForm="(item) => saveItem(item)" :site="selectedSite"/>
    <haul-approval-form
      :formIsOpen="approvalFormIsOpen"
      @closeForm="closeForm()"
      @submitForm="(item) => saveApproval(item)"
      :approvalData="approvalData"/>
    <v-row class="mx-2 mt-1">
      <v-col cols="9">
      </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <template v-if="isGeneralContractor">
          <v-btn
            elevation="0" outlined
            prepend variant="outlined"
            @click="openSiteForm">
            <v-icon>mdi-plus</v-icon>New Hauling Request
          </v-btn>
          <!--<v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" style="display: inline;">

                <v-btn
                  elevation="0" outlined
                  prepend variant="outlined"
                  disabled
                  @click="openSiteForm">
                  <v-icon>mdi-plus</v-icon>New Hauling Request
                </v-btn>

              </div>
            </template>
            <span> Must have at least one segment </span>
          </v-tooltip>-->
        </template>
      </v-col>
    </v-row>

    <hauls-table
      :haulRequests="haulRequests"
      ref="haulsTable"
      @openApprovalForm="(haulRequest, isExport) => openApprovalForm(haulRequest, isExport)"
      @processApproval="(approval, statusId, decisionNote) => processApproval(approval, statusId, decisionNote)"
      @sendApproval="(approval) => sendApproval(approval)"
      @editApproval="(approval) => openApprovalForm(null,null,approval)"
      @selectDepthAndApprove="(approval, callback) => selectDepthAndApprove(approval, callback)"
      />
  </div>

</template>

<script>
// import { ref } from 'vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import HaulsTable from '@/components/hauls/HaulsTable.vue'
import HaulForm from '@/components/hauls/HaulForm.vue'
import HaulApprovalForm from '@/components/hauls/HaulApprovalForm.vue'

export default {
  components: {
    HaulsTable,
    HaulForm,
    HaulApprovalForm
  },
  data: () => ({
    activeTab: 0,
    haulRequests: [],
    formIsOpen: false,
    approvalFormIsOpen: false,

    generalContractorCompanyTypeId: 1,
    approvalData: {},
    volumeUnitOfMeasurementTypeId: 1,
    approvalAcceptedStatusId: 2,
    approvalDeclinedStatusId: 3,
    exportTypeId: 1,
    exportHaulRequestTypeId: 1,
    importHaulRequestTypeId: 2,
    haulingCompanyTypeId: 2
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies,
      selectedCompany: (state) => state.comp.selectedCompany,
      sites: (state) => state.site.sites,
      selectedSiteId: (state) => state.site.selectedSiteId,
      unitOfMeasurementTypes: (state) => state.types.unitsOfMeasurement
    }),
    ...mapGetters({
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isGeneralContractor () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.generalContractorCompanyTypeId
    },
    filters () {
      const filters = []
      if (this.selectedCompanyProfile.companyTypeId === this.generalContractorCompanyTypeId) filters.push({ name: 'SiteId', values: [this.selectedSiteId] })
      return filters
    },
    selectedSite () {
      if (this.sites && this.sites.length && this.selectedSiteId) return this.sites.find(x => x.id === this.selectedSiteId)
      return {}
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    selectedSiteId: {
      handler (value) {
        if (value) {
          this.refreshTemplate(true)
        }
      },
      deep: true,
      immediate: true
    },
    selectedCompanyProfile: {
      handler () {
        this.refreshTemplate(true)
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.$store.dispatch('action/refreshActionCount')
    },
    async refreshTemplate (hardRefresh = false) {
      await this.$store.dispatch('action/refreshActionCount')
      this.loadHaulRequests(hardRefresh)
    },
    async loadHaulRequests (hardRefresh = false) {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.haulingRequest.read(search)
      const expandedRequests = hardRefresh ? [] : this.haulRequests.filter(request => request.expanded).map(request => request.id)
      const haulRequests = res.haulingRequests.map((haulRequest) => {
        return {
          ...haulRequest,
          expanded: expandedRequests.some(x => x === haulRequest.id)
        }
      }
      )
      haulRequests.forEach(haulRequest => {
        haulRequest.haulingCompanyProfile.company = res.companies.find(company => company.id === haulRequest.haulingCompanyProfile.companyId)
        if (haulRequest.exportingContractorCompanyProfile) haulRequest.exportingContractorCompanyProfile.company = res.companies.find(company => company.id === haulRequest.exportingContractorCompanyProfile.companyId)
        if (haulRequest.importingContractorCompanyProfile) haulRequest.importingContractorCompanyProfile.company = res.companies.find(company => company.id === haulRequest.importingContractorCompanyProfile.companyId)

        const loadedHaulRequest = this.haulRequests.find(req => req.id === haulRequest.id && !hardRefresh)
        if (loadedHaulRequest) {
          haulRequest.tabs = loadedHaulRequest.tabs
          haulRequest.selectedTab = loadedHaulRequest.selectedTab
          haulRequest.isExport = loadedHaulRequest.isExport
        } else {
          let exportTab = haulRequest.typeId === this.exportHaulRequestTypeId
          if (this.selectedCompanyProfile.companyTypeId !== this.haulingCompanyTypeId && (haulRequest.siteId !== this.selectedSiteId)) exportTab = !exportTab

          haulRequest.tabs = exportTab ? ['Exports', 'Imports'] : ['Imports', 'Exports']
          haulRequest.selectedTab = 0
          haulRequest.isExport = haulRequest.typeId === this.exportTypeId
        }
        haulRequest.totalVolume = this.totalVolume(haulRequest)
      })
      this.haulRequests = haulRequests
    },
    openSiteForm () {
      this.formIsOpen = true
    },
    closeForm () {
      this.formIsOpen = false
      this.approvalFormIsOpen = false
      this.approvalData = {}
    },
    async saveItem () {
      await this.$store.dispatch('action/refreshActionCount')
      this.loadHaulRequests()
    },
    viewSegment (item) {
      this.$router.push(`/segments?siteId=${item.id}`)
    },
    viewInfo (item) {
      this.$router.push(`/site?siteId=${item.id}`)
    },
    openApprovalForm (haulRequest = null, isExport = null, existingApproval = null) {
      this.approvalFormIsOpen = true
      this.approvalData = {
        haulRequest: haulRequest || (existingApproval ? this.haulRequests.find(x => x.id === existingApproval.haulRequestId) : null),
        isExport: existingApproval ? existingApproval.isExport : isExport,
        existingApproval: existingApproval
      }
    },
    async saveApproval (approval) {
      console.log(approval)
      const res = await (approval.id ? this.$api.haulingRequest.put(approval, 'approval') : this.$api.haulingRequest.post(approval, 'approval'))
      if (res && res.id) {
        this.$store.dispatch('app/showSuccess', `${res.isExport ? 'Export' : 'Import'} Approval ${res.code} has been ${approval.id ? 'updated' : 'created'}.`)
        this.$store.dispatch('action/refreshActionCount')
        this.loadHaulRequests()
        this.closeForm()
      } else this.$store.dispatch('app/showError', 'Failed to create approval.')
    },
    totalVolume (haulRequest) {
      const totals = this.unitOfMeasurementTypes.filter(x => x.unitOfMeasureTypeId === this.volumeUnitOfMeasurementTypeId).map((uom) => {
        return {
          exportingVolume: 0,
          allocatedVolume: 0,
          acceptedVolume: 0,
          importingVolume: 0,
          unitOfMeasurement: { ...uom }
        }
      })
      const exports = haulRequest.exportApprovals
      const imports = haulRequest.importApprovals
      const exportPrimary = haulRequest.typeId === this.exportHaulRequestTypeId
      exports.forEach(exp => {
        const index = totals.findIndex(x => x.unitOfMeasurement.id === exp.loadUnitOfMeasureId)
        const volume = exp.loadVolume * exp.loadQuantity
        totals[index].exportingVolume += volume
        if (!exportPrimary && exp.haulRequestApprovalStatusId !== this.approvalDeclinedStatusId) {
          totals[index].allocatedVolume += volume
        }
        if (exportPrimary && exp.haulRequestApprovalStatusId === this.approvalAcceptedStatusId) totals[index].acceptedVolume += volume
      })
      imports.forEach(imp => {
        const index = totals.findIndex(x => x.unitOfMeasurement.id === imp.loadUnitOfMeasureId)
        const volume = imp.loadVolume * imp.loadQuantity
        totals[index].importingVolume += volume
        if (exportPrimary && imp.haulRequestApprovalStatusId !== this.approvalDeclinedStatusId) {
          totals[index].allocatedVolume += volume
        }
        if (!exportPrimary && imp.haulRequestApprovalStatusId === this.approvalAcceptedStatusId) totals[index].acceptedVolume += volume
      })
      return totals
    },
    async processApproval (approval, statusId, decisionNote) {
      const res = await this.$api.haulingRequest.get(`approval/${approval.id}/change-status/${statusId}${decisionNote ? `?decisionNote=${decisionNote}` : ''}`)
      if (res === true) {
        this.$store.dispatch('app/showSuccess', `Approval successfully ${statusId === this.approvalAcceptedStatusId ? 'accepted' : 'declined'}`)
        this.$store.dispatch('action/refreshActionCount')
        this.loadHaulRequests()
      } else this.$store.dispatch('app/showError', `Failed to ${statusId === this.approvalAcceptedStatusId ? 'accept' : 'decline'} approval.`)
      return res
    },
    async sendApproval (approval) {
      const res = await this.$api.haulingRequest.get(`approval/${approval.id}/send`)
      if (res === true) {
        this.$store.dispatch('app/showSuccess', 'Approval successfully sent')
        this.$store.dispatch('action/refreshActionCount')
        this.loadHaulRequests()
      } else this.$store.dispatch('app/showError', 'Failed to send approval.')
    },
    async selectDepthAndApprove (approval, callback) {
      const res = await this.$api.haulingRequest.setImportSegmentDepth(approval.id, approval.siteSegmentDepthId)
      const res2 = res === true ? await this.processApproval(approval, this.approvalAcceptedStatusId) : false
      callback(res2)
    }
  }

}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
