<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog v-model="formOpen" persistent style="width:80%" width="60%" min-width="600px">
        <v-card style="width:100%">
          <v-card-title>Find Hauling Company</v-card-title>
          <v-card-text>
            <v-container style="width:100%">
              <v-row>
                <v-col>
                  <v-text-field dense hide-details="" label="Search" v-model="searchText" outlined />
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn @click="loadCompanies()" color="success">Search</v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col>

                  <v-data-table
                    :items="companies"
                    :headers="headers"
                    :footer-props="footerProps"
                    :items-per-page="itemsPerPage"
                    :loading="loading"
                    :server-items-length="total"
                    :option="options"
                    style="width: 100%"
                    @pagination="updatePagination"
                    @update:options="updateOptions"
                  >
                    <template #[`item.address`]="{ item }">
                      <span v-if="item.addresses && item.addresses.length">
                        {{ formatAddress(item.addresses[0]) }}
                      </span>
                    </template>
                    <template #[`item.phone`]="{ item }">
                      <span v-if="item.phoneNumbers && item.phoneNumbers.length">
                        {{ formatPhone(item.phoneNumbers[0]) }}
                      </span>
                    </template>
                    <template #[`item.email`]="{ item }">
                      <span v-if="item.emails && item.emails.length">
                        {{ item.emails[0].email }}
                      </span>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn color="primary" small @click="select(item)">SELECT</v-btn>
                    </template>
                  </v-data-table>
                </v-col>

              </v-row>
            </v-container>``
          </v-card-text>
          <v-card-actions>
            <v-row class="no-gutters">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn text class="mr-2"  @click="closeForm">Close</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { formatAddress, formatPhone } from '@/helpers/FormatHelper.js'
export default {
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchText: '',
    formOpen: false,
    companies: [],

    // pagination stuff
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1]
    },
    itemsPerPage: 20,
    options: null,
    total: 0,
    loading: false
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      propCompanies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    allFilters () {
      let filters = []

      filters = filters.concat(this.filters)
      return filters
    },
    headers () {
      const headers = []
      headers.push({ text: 'Name', value: 'name', sortable: false })
      headers.push({ text: 'Address', value: 'address', sortable: false })
      headers.push({ text: 'Phone', value: 'phone', sortable: false })
      headers.push({ text: 'Email', value: 'email', sortable: false })
      headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'end' })
      return headers
    }
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
        if (value === true) this.loadCompanies()
      }
    }
  },
  methods: {
    closeForm () {
      this.$emit('closeForm')
    },
    async loadCompanies () {
      this.loading = true
      const res = await this.$api.companies.read(createPaginationObject(null, this.searchText, this.allFilters, ['Address', 'Phone', 'Email']))
      this.loading = false
      this.total = res.total
      console.log(res)
      this.companies = res.companies
    },
    updatePagination (pagination) {
      this.pagination = pagination
    },
    updateOptions (options) {
      this.options = options
    },
    formatAddress (address) {
      return formatAddress(address)
    },
    formatPhone (phone) {
      return formatPhone(phone)
    },
    select (item) {
      this.$emit('setHaulingCompany', item)
      this.closeForm()
    }

  }
}
</script>

<style>
  .v-dialog > .v-overlay__content {
    width:80%;
  }
</style>
