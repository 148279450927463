<template>
  <v-row justify="center">
    <v-col cols="12">
      <haul-company-search-form ref="haulerCompaniesDialog" :formIsOpen="companySearchFormOpen" :filters="[]" @setHaulingCompany="(company) => selectedHaulingCompany = company" @closeForm="companySearchFormOpen = false"/>
      <v-dialog v-model="formOpen" persistent style="width:80%" width="50%" min-width="600px">
        <v-card style="width:100%">
          <v-card-title>New {{ this.submitData.isExport ? 'Export' : 'Import' }} Hauling Request</v-card-title>
          <v-card-text>
            <v-container style="width:100%">
              <v-form v-model="valid" ref="form">
                <span class="text-subtitle-1">Hauling Request Details</span>
                <v-row class="mt-1">
                  <v-col cols="3">
                    <v-radio-group v-model="submitData.isExport" dense @change="loadSegments()" row>
                        <v-radio
                          label="Export"
                          color="primary"
                          :value="true"
                          dense

                        ></v-radio>
                        <v-radio
                          label="Import"
                          color="primary"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="Extraction Site" :value="site.name" disabled />
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      label="Segment"
                      :items="segments"
                      v-model="segment"
                      item-text="name"
                      :item-value="(item) => item"
                      @input="loadDepths()"
                      :rules="[rules.requiredObject]"
                      ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      label="Depth"
                      :disabled="!segment || !segment.id"
                      :items="segment && segment.segmentDepths ? segment.segmentDepths : []"
                      v-model="submitData.segmentDepthId"
                      :item-text="(item) => `${item.name} (${item.depth.name})`"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-1">

                  <v-col cols="3">
                    <v-text-field
                      label="Hauler"
                      readonly
                      style="cursor: pointer"
                      @click="openCompanySearch()"
                      v-model="selectedHaulingCompany.legalName"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="9" class="d-flex align-center">
                    <v-text-field
                      label="Notes"
                      v-model="submitData.note"
                    />
                  </v-col>
                </v-row>
                <span class="text-subtitle-1">Initial Hauling Approval</span>
                <v-row class="mt-1">
                  <v-col cols="3">
                    <v-text-field
                      v-model="computedLoadQuantity"
                      @keypress="(event) => filter(event)"
                      label="Number of Loads (10m³ each)"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <span>Total of {{ (computedLoadQuantity * 10).toLocaleString() }}m³</span>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row class="no-gutters">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn text class="mr-2"  @click="closeForm">Close</v-btn>
                <v-btn variant="outlined" color="success" elevation="0" @click="submitHaulRequest">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import HaulCompanySearchForm from './HaulCompanySearchForm.vue'
export default {
  components: { HaulCompanySearchForm },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    existingRequest: {
      type: Object,
      default: () => {}
    },
    site: {
      type: Object,
      default: () => {}
    },
    isExport: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    formOpen: false,
    companySearchFormOpen: false,
    defaultSubmitData: {
      id: null,
      haulingCompanyProfileId: null,
      segmentDepthId: null,
      haulStartDate: null,
      typeId: 1,
      haulEndDate: null,
      isExport: true,
      loadQuantity: 0,
      loadVolume: 10,
      loadUnitOfMeasureId: 3,
      note: ''
    },
    rules: {
      required: value => !!value || 'Required.',
      requiredObject: value => !!Object.keys(value).length || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Must be a valid e-mail.'
      }
    },
    valid: false,
    submitData: {},
    segments: [],
    segment: {},
    selectedHaulingCompany: {},
    haulingCompanyTypeId: 2,
    segmentExportTypeId: 1
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    computedLoadQuantity: {
      get () {
        return this.submitData.loadQuantity
      },
      set (value) {
        this.$set(this.submitData, 'loadQuantity', (/^\d+$/.test(value) ? Number(value) : 0))
      }
    }
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
        if (value) this.loadSegments()
      }
    },
    existingRequest: {
      immediate: true,
      handler (value) {
        if (value && value.id) {
          this.submitData = { ...value }
        } else {
          this.resetForm()
        }
      }
    },
    site: {
      immediate: true,
      handler (value) {
        if (value && value.id) this.loadSegments()
      }
    },
    isExport: {
      handler (value) {
        this.submitData.isExport = value
      }
    }
  },
  methods: {
    async submitHaulRequest () {
      if (!this.$refs.form.validate()) return
      const haulingCompanyProfile = this.selectedHaulingCompany && this.selectedHaulingCompany.companyProfiles ? this.selectedHaulingCompany.companyProfiles.find(x => x.companyTypeId === this.haulingCompanyTypeId) : {}
      if (!haulingCompanyProfile) return
      const data = { ...this.submitData, haulingCompanyProfileId: haulingCompanyProfile.id }
      const res = await this.$api.haulingRequest.post(data)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Haul Request successfully created')
        this.$emit('submitForm')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Haul Request')
      }
    },
    closeForm () {
      this.resetForm()
      this.$emit('closeForm')
    },
    resetForm () {
      this.submitData = { ...this.defaultSubmitData, isExport: this.isExport }
      this.segment = {}
      this.selectedHaulingCompany = {}
    },
    async loadSegments () {
      const res = await this.$api.segments.read(createPaginationObject(null, null, [{ name: 'SiteId', values: [this.site.id] }, { name: 'Type', values: [(this.submitData.isExport ? 'Donor' : 'Recipient')] }]))
      this.segments = res.segments.map(seg => {
        return { ...seg, segmentDepths: [] }
      })
      if (this.segment.id == null || !this.segments.some(segment => segment.id === this.segment.id)) {
        this.submitData.segmentDepthId = null
        this.segment = {}
      }
    },
    async loadDepths () {
      const res = await this.$api.segmentDepths.read(createPaginationObject(null, null, [{ name: 'SegmentId', values: [this.segment.id] }, { name: 'Type', values: [(this.submitData.isExport ? 'Donor' : 'Recipient')] }]))
      this.segment.segmentDepths = res.segmentDepths
      if (!res.segmentDepths.some(depth => depth.id === this.submitData.segmentDepthId)) this.submitData.segmentDepthId = null
    },
    openCompanySearch () {
      this.companySearchFormOpen = true
    },
    filter (evt) {
      evt = (evt) || window.event
      const expect = evt.target.value.toString() + evt.key.toString()

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    log (inp) {
      console.log(inp)
    }

  }
}
</script>

<style>
  .v-dialog > .v-overlay__content {
    width:80%;
  }
</style>
