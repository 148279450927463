<template>
  <hauls-template />
</template>
<script>
import HaulsTemplate from '@/components/hauls/HaulsTemplate.vue'

export default {
  name: 'HaulsView',
  components: {
    HaulsTemplate
  }
}
</script>
